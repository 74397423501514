
import { Component } from 'vue-property-decorator';
import SalesTable from '@/components/sales/SalesTable.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import SalesFilter from '@/components/sales/SalesFilter.vue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import SalesLoader from '@/components/sales/SalesLoader.vue';
import SyncForm from '@/components/sync/SyncForm.vue';
import SyncApiService from '@/api/http/SyncApiService';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';

@Component({
  components: { SyncForm, SalesLoader, VRangePicker, SalesFilter, SalesTable, VWrapper },
})
export default class Sync extends mixins(Notification) {
  public $refs!: {
    form: InstanceType<typeof SyncForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  public save() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (res) {
        const api: SyncApiService = new SyncApiService();
        api
          .sync(this.$refs.form.getData())
          .then(() => {
            this.notifyInfo('sync.notification.running');
          })
          .catch((err: Error) => {
            console.error(err);
            this.notifyInfo('sync.notification.error');
          });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
