
import { Component, Watch, Prop } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Permission } from '@/enums/Permission';
import { Venue } from '@/interfaces/models/Venue';
import { Category } from '@/interfaces/models/Category';
import { Pagination } from '@/interfaces/api/Pagination';
import VenueApiService from '@/api/http/VenueApiService';
import { AxiosResponse } from 'axios';
import { map } from 'lodash';

const foodcard = namespace('foodcard');

@Component({
  components: { VFormBuilder },
})
export default class SyncForm extends mixins(StackedForm) {
  @foodcard.State('categories') public categories!: Category[];
  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  public currentMode: string = '';
  public currentDirection: string = '';
  public mainVenue: string = '';
  public masterVenues: Pagination<Venue> | Venue[] = [];
  public slaveVenues: Pagination<Venue> | Venue[] = [];

  get slaveVenuesFiltered() {
    const venues = this.slaveVenues as Venue[];

    if (this.currentMode === 'master-to-slaves') {
      return venues.filter((venue: Venue) => venue.masterId === this.mainVenue);
    }
    return venues;
  }

  get items() {
    return [
      {
        name: 'mode',
        type: InputType.Select,
        label: 'sync.form.mode',
        rules: 'required',
        items: this.modes,
        change: this.setCurrentMode,
      },
      {
        name: 'direction',
        type: InputType.Select,
        label: 'sync.form.direction',
        rules: 'required',
        items: this.directions,
        visible: !!this.currentMode,
        change: this.setCurrentDirection,
      },
      {
        name: 'main',
        type: InputType.Autocomplete,
        label: 'sync.form.main',
        rules: 'required',
        items: this.masterVenues,
        itemText: 'name',
        itemValue: '_id',
        visible: !!this.currentMode && !!this.currentDirection && !!(this.masterVenues as Venue[])?.length,
        change: this.setMainVenue,
      },
      {
        name: 'slaves',
        type: InputType.Autocomplete,
        label: 'sync.form.slaves',
        rules: 'required',
        items: this.slaveVenuesFiltered,
        multiple: true,
        itemText: 'name',
        itemValue: '_id',
        selectAll: this.currentMode === 'master-to-slaves',
        visible:
          !!this.currentMode && !!this.currentDirection && !!this.mainVenue && !!this.slaveVenuesFiltered?.length,
      },
      {
        name: 'deleteMode',
        type: InputType.Select,
        label: 'sync.form.deleteMode',
        rules: 'required',
        items: this.deleteModes,
        visible: !!this.currentMode && !!this.currentDirection && !!this.mainVenue,
      },
      {
        name: 'articleCategories',
        type: InputType.Select,
        label: 'sync.form.articleCategoryProps',
        items: this.articleCategoryProps,
        multiple: true,
        visible: !!this.currentMode && !!this.currentDirection && !!this.mainVenue,
      },
      {
        name: 'categoryMasterIds',
        type: InputType.Select,
        label: 'sync.form.categories',
        items: this.masterVenueCategories,
        multiple: true,
        visible: !!this.currentMode && !!this.currentDirection && !!this.mainVenue,
      },
      {
        name: 'articles',
        type: InputType.Select,
        label: 'sync.form.articleProps',
        items: this.articleProps,
        multiple: true,
        visible: !!this.currentMode && !!this.currentDirection && !!this.mainVenue,
      },
      {
        name: 'venue',
        type: InputType.Select,
        label: 'sync.form.venueProps',
        items: this.venueProps,
        multiple: true,
        visible: !!this.currentMode && !!this.currentDirection && !!this.mainVenue,
      },
      {
        name: 'optionGroups',
        type: InputType.Select,
        label: 'sync.form.optionGroupProps',
        items: this.optionGroupProps,
        multiple: true,
        visible: !!this.currentMode && !!this.currentDirection && !!this.mainVenue,
      },
      {
        name: 'optionArticles',
        type: InputType.Select,
        label: 'sync.form.optionArticleProps',
        items: this.optionArticleProps,
        multiple: true,
        visible: !!this.currentMode && !!this.currentDirection && !!this.mainVenue,
      },
    ];
  }

  get directions() {
    const items = ['d2d', 'd2p', 'p2d', 'p2p'];
    return items.map((val: string) => ({
      text: this.$t(`sync.directions.${val}`),
      value: val,
    }));
  }

  get modes() {
    const items = ['master-to-slaves', 'slave-to-slaves'];
    return items.map((val: string) => ({
      text: this.$t(`sync.modes.${val}`),
      value: val,
    }));
  }

  get deleteModes() {
    const items = ['ignore', 'soft', 'hard'];
    return items.map((val: string) => ({
      text: this.$t(`sync.deleteModes.${val}`),
      value: val,
    }));
  }

  get masterVenueCategories() {
    return (this.categories || []).map((cat: Category) => ({
      value: cat._id,
      text: this.$options.filters!.localized(cat.name),
    }));
  }

  get articleCategoryProps() {
    const props = [
      'sortOrder',
      'visible',
      'displayMode',
      'displayIdentifiers',
      'mainCategory',
      'availableHours',
      'name',
      'description',
      'assets',
      'icon',
      'tags',
      'constrains',
      'features',
      'mainRecommendations',
    ];
    return props.map((prop: string) => ({
      text: this.$t(`sync.props.articleCategory.${prop}`),
      value: prop,
    }));
  }

  get articleProps() {
    const props = [
      'price',
      'basePrice',
      'taxTakeaway',
      'taxInside',
      'sortOrder',
      'visible',
      'isActive',
      'deposit',
      'isControlArticle',
      'internalNote',
      'internalName',
      'externalId',
      'number',
      'priceIsLocked',
      'description',
      'name',
      'info',
      'shortDescription',
      'assets',
      'icon',
      'banner',
      'measurement',
      'groupDependencies',
      'groups',
      'defaults',
      'recommendations',
      'priceByType',
      'displayPriceByType',
      'availability',
      'tags',
      'constrains',
      'counterArticle',
      'mainRecommendations',
      'articleRelativeBrand',
      'styles',
      'additives',
      'allergens',
      'features',
      'traces',
      'externalOrderProviderSettings',
      'loyaltyId',
      'requiredLoyaltyPoints',
      'plu',
      'gtin',
      'nutritions',
      'displayMode',
      'displayModeSot',
      'displayModeMobile',
    ];
    return props.map((prop: string) => ({
      text: this.$t(`sync.props.article.${prop}`),
      value: prop,
    }));
  }

  get venueProps() {
    const props = [
      'movDelivery',
      'preorderTakeAway',
      'preorderIn',
      'deliveryEnabled',
      'sendProvisionReportPeriod',
      'isServiceActivated',
      'offsets',
      'invoiceReceiver',
      'slot',
      'legal',
      'openingHours',
      'deliveryHours',
    ];
    return props.map((prop: string) => ({
      text: this.$t(`sync.props.venue.${prop}`),
      value: prop,
    }));
  }

  get optionGroupProps() {
    const props = [
      'hasMultiple',
      'limit',
      'sortOrder',
      'requiredAmount',
      'name',
      'description',
      'shortDescription',
      'backgroundImage',
      'tags',
      'displayMode',
      'displayModeSot',
      'displayModeMobile',
    ];
    return props.map((prop: string) => ({
      text: this.$t(`sync.props.optionGroup.${prop}`),
      value: prop,
    }));
  }

  get optionArticleProps() {
    const props = [
      'price',
      'basePrice',
      'taxTakeaway',
      'taxInside',
      'sortOrder',
      'visible',
      'isActive',
      'deposit',
      'optionNumber',
      'priceIsLocked',
      'internalNote',
      'internalName',
      'priceIsLocked',
      'requirements',
      'name',
      'description',
      'shortDescription',
      'info',
      'assets',
      'measurement',
      'priceByType',
      'displayPriceByType',
      'availability',
      'tags',
      'constrains',
      'articleRelativeBrand',
      'styles',
      'additives',
      'allergens',
      'features',
      'traces',
      'externalOrderProviderSettings',
      'loyaltyId',
      'requiredLoyaltyPoints',
      'plu',
      'gtin',
      'externalId',
      'nutritions',
    ];
    return props.map((prop: string) => ({
      text: this.$t(`sync.props.optionArticle.${prop}`),
      value: prop,
    }));
  }

  public setCurrentMode(mode: string) {
    this.currentMode = mode;
  }

  public setCurrentDirection(direction: string) {
    this.currentDirection = direction;
  }

  public setMainVenue(venue: string) {
    this.mainVenue = venue;
  }

  public async fetchVenues(env: 'dev' | 'prod') {
    const api: VenueApiService = new VenueApiService();

    return await api.listByEnv(env, { hidden: true }).then((res: AxiosResponse<Pagination<Venue> | Venue[]>) => {
      return res.data;
    });
  }

  public getData() {
    return {
      ...this.$refs.form.form,
      update: {
        articleCategories: this.$refs.form.form.articleCategories,
        articles: this.$refs.form.form.articles,
        optionGroups: this.$refs.form.form.optionGroups,
        optionArticles: this.$refs.form.form.optionArticles,
        venue: this.$refs.form.form.venue,
      },
    };
  }

  @Watch('mainVenue')
  public async onMainVenueChange() {
    const masterId = (this.masterVenues as Venue[]).find((venue: Venue) => venue._id === this.mainVenue)?.masterId;

    await this.setCategoryFilter({ venue: masterId || this.mainVenue });
    await this.getCategories();
  }

  @Watch('currentDirection')
  public async onDirectionChange() {
    this.$refs.form.setData({
      mode: this.currentMode,
      direction: this.currentDirection,
      main: '',
      deleteMode: undefined,
      slaves: [],
      articleCategories: undefined,
      optionGroups: undefined,
      articles: undefined,
      venue: undefined,
      optionArticles: undefined,
    });
    this.setMainVenue('');

    if (!this.currentDirection) {
      return;
    }

    const masterEnv = this.currentDirection.startsWith('d')
      ? 'dev'
      : this.currentDirection.startsWith('p')
      ? 'prod'
      : 'dev';
    const slaveEnv = this.currentDirection.endsWith('d') ? 'dev' : this.currentDirection.endsWith('p') ? 'prod' : 'dev';

    this.masterVenues = await this.fetchVenues(masterEnv);
    this.slaveVenues = await this.fetchVenues(slaveEnv);
  }
}
