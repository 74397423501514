
import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import { SyncJobStatus } from '@/enums/SyncJobStatus';
import moment from 'moment';

@Component({
  components: {},
})
export default class SyncFilter extends Vue {
  public selectedStatus: SyncJobStatus | null = null;
  public selectedCreatedAtDate: string | null = null;
  public createdAtMenu: boolean = false;

  get selection() {
    return {
      status: this.selectedStatus,
      createdAt: this.selectedCreatedAtDate,
    };
  }

  get status(): string[] {
    return Object.values(SyncJobStatus);
  }

  public selectToday() {
    this.selectedCreatedAtDate = moment().format('YYYY-MM-DD');
    this.createdAtMenu = false;
  }

  @Watch('selection')
  @Emit('change')
  public onSearch() {
    return this.selection;
  }
}
